@import '../../styles/colors';

.header {
    background-color: var(--background-secondary);
    height: 68px;
    padding: 0 14px;
    .title {
        line-height: 1.3rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
    }
}