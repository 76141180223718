@import '../../styles/_colors';

.overlay {
    align-items: center;
    animation: fadeIn 0.2s forwards;
    background-color: var(--background-primary);
    bottom: 58px;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    .preview {
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        width: 70%;
    }
    img {
        width: 100%;
    }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}