@import '../../styles/_colors';

.picture {
    align-items: center;
    display: flex;
    background-color: var(--delimiter);
    justify-content: center;
}

.initials {
    color: #ffffff;
    font-weight: 700;
}