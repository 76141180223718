@import '../../styles/colors';

.input {
    background: var(--background-secondary);
    border-top: 1px solid var(--delimiter);
    input {
        padding: 0 16px;
    }
    .input-container {
        position: relative;
        width: 100%;
        div[contenteditable] {
            max-height: 140px;
            outline: 0;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 16px 16px 18px 16px;
            width: 100%;
        }
        .placeholder {
            color: var(--text-secondary);
            cursor: text;
            padding: 16px;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}

.emoji-button {
    margin: 13px 0 0 16px;
}

.action-buttons {
    align-items: center;
    display: flex;
    margin: 0 8px 0 16px;
}

.recorder {
    align-items: center;
    display: flex;
    .mic-button {
        margin-right: 16px;
        svg {
            fill: var(--text-secondary);
            transition: fill 0.1s;
        }
    }
    .progress {
        margin-right: 6px;
    }
    &.recording {
        .mic-button {
            svg {
                fill: rgb(244, 57, 91);
            }
        }
    }
}