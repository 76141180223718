@import '../../styles/_colors';

.container {
    background-color: var(--background-primary);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.15s;
    z-index: 20;
    &.visible {
        transform: translateX(0);
    }
}

.header {
    align-items: center;
    background-color: var(--background-secondary);
    display: flex;
    height: 68px;
    padding: 0 8px;
    .back-button {
        align-items: center;
        border-radius: 12px;
        display: flex;
        height: 38px;
        padding: 0 12px;
        transition: background-color 0.1s;
        &:hover {
            background-color: var(--background-active);
        }
        .title {
            align-items: center;
            display: flex;
            font-weight: 500;
            .icon {
                margin-right: 10px;
            }
        }
    }
}

.user {
    align-items: center;
    display: flex;
    line-height: 1.3rem;
    padding: 14px;
    .picture {
        margin-right: 14px;
    }
    .username {
        font-size: 1.25rem;
        font-weight: 500;
    }
    .phone {
        color: var(--text-secondary);
        font-size: 0.875rem;
    }
}

.screen {
    background-color: var(--background-primary);
    bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
}