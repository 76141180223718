@import './_colors';

.screen {
    align-items: center;
    background: #c303e5;
    background: linear-gradient(130deg, #c303e5 0%, #7d00ea 100%);
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
}

.toolbar {
    -webkit-app-region: drag;
    height: 36px;
    width: 100%;
}

.app {
    background-color: var(--background-primary);
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100vw;
}

.chat {
    background-color: var(--background-primary);
    color: var(--text-primary);
    display: grid;
    flex: 1;
    grid-template-columns: 72px minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    min-height: 0;
    & > :nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
    }
    & > :nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
    }
}

.alert-bar {
    align-items: center;
    background-color: red;
    color: #ffffff;
    display: flex;
    height: 36px;
    padding: 0 14px;
}

.personal-link {
    background-color: var(--background-secondary);
    margin-top: 2rem;
    max-width: 900px;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .chat {
        grid-template-columns: minmax(310px, 0.4fr) 1fr;
    }
}

@media screen and (min-width: 1400px) {
    .app {
        border-radius: 16px;
        height: calc(100vh - 100px);
        max-height: 900px;
        max-width: 1400px;
    }
}