@import '../../styles/_colors';

.picker {
    background-color: var(--background-secondary);
    border-top: 1px solid transparent;
    transition: height 0.3s ease-out;
    &.visible {
        border-color: var(--delimiter);
    }
}

.category {
    color: var(--text-secondary);
    transition: color 0.2s;
    &:hover, &.active {
        color: var(--text-primary);
    }
}