@import '../../styles/_colors';

.button {
    align-items: center;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    transition: background-color 0.1s;
    &.active {
        background-color: var(--background-primary);
    }
}