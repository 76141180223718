@import '../../styles/_colors';

.container {
    animation: showContainer 0.2s forwards;
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

@keyframes showContainer {
    from {
        background-color: rgba(10,10,10,0);
    }
    to {
        background-color: rgba(10,10,10,0.4);
    }
}

.modal {
    animation: showModal 0.2s forwards;
    background-color: var(--background-secondary);
    border-radius: 12px;
    max-width: calc(100% - 62px);
    .modal-header {
        align-items: center;
        border-bottom: 1px solid var(--delimiter);
        display: flex;
        flex-direction: row;
        height: 56px;
        justify-content: space-between;
        padding: 0 16px;
    }
    .modal-content {
        max-height: calc(100vh - 126px);
        min-height: 100px;
        overflow-y: auto;
        padding: 16px;
    }
    .modal-actions {
        display: flex;
        padding: 10px 10px 14px 10px;
    }
}

@keyframes showModal {
    from {
        transform: scale(0.7);
    }
    to {
        transform: scale(1);
    }
}