.ring-display {
    align-items: center;
    background-color: var(--background-secondary);
    border-radius: 12px;
    box-shadow: 0 0 32px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    height: 76px;
    justify-content: space-between;
    left: calc(50vw - 200px);
    padding: 0 16px;
    position: absolute;
    top: 22px;
    width: 400px;
    .caller {
        align-items: center;
        display: flex;
        .caller-name {
            font-size: 1.2rem;
            font-weight: 500;
            margin-left: 10px;
        }
    }
    .actions {
        display: flex;
        .button {
            border-radius: 24px;
            height: 48px;
            width: 48px;
            &.button-answer {
                background-color: green;
                margin-right: 6px;
            }
            &.button-decline {
                background-color: red;
            }
        }
    }
}