@import '../../styles/_colors';

.button {
    background: transparent;
    border-radius: 12px;
    font-weight: 500;
    height: 46px;
    transition: background 0.1s;
    padding: 0 16px;
    width: 100%;
    &:hover {
        background: rgba(184, 0, 255, 0.15);
    }
    &.primary {
        background: var(--brand-primary);
        background: linear-gradient(130deg, #c303e5 0%, #7d00ea 100%);
        color: #ffffff;
        &:hover {
            background: linear-gradient(130deg, #d403f9 0%, #9808f8 100%);
        }
    }
    &.disabled {
        background: var(--background-active);
        color: var(--text-secondary);
        &:hover {
            background: var(--background-active);
            color: var(--text-secondary);
            cursor: default;
        }
    }
}