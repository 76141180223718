@import '../../styles/_colors';

.list {
    
    height: 100%;
    user-select: none;
    overflow-x: hidden;
    overflow-y: auto;
}

.search {
    // border-right: 1px solid var(--delimiter);
    border-bottom: 1px solid var(--delimiter);
    padding: 6px;
    input {
        background-color: var(--background-secondary);
        &:focus {
            outline: 2px solid var(--brand-primary);
        }
    }
}

.conversation {
    align-items: center;
    border-bottom: 1px solid var(--delimiter);
    display: flex;
    height: 72px;
    line-height: 1.3rem;
    padding: 0 14px;
    .last-message, .time {
        color: #999999;
        font-size: 0.9rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .last-message {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .badge {
        align-items: center;
        background-color: var(--brand-primary);
        border-radius: 9px;
        color: #ffffff;
        display: flex;
        font-size: 0.7rem;
        font-weight: 600;
        height: 18px;
        margin-top: 2px;
        padding: 0 6px;
    }
    &:hover, &.active {
        background-color: var(--background-active);
        transition: background-color 0.1s;
    }
}