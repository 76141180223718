@import '../../styles/_colors';

.list {
    height: 100%;
    overflow-y: auto;
    padding: 8px 8px 16px 8px;
}

.list-item {
    align-items: center;
    border-radius: 12px;
    color: var(--text-secondary);
    display: flex;
    height: 46px;
    margin-bottom: 6px;
    padding: 2px 14px;
    transition: background-color 0.1s, color 0.1s;
    &.clickable {
        &:hover {
            background-color: var(--background-secondary);
            color: var(--text-primary);
        }
    }
    &.active {
        background-color: var(--background-secondary);
        font-weight: 500;
    }
    .icon {
        margin-right: 10px;
    }
    .content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        .value {
            color: var(--text-secondary);
        }
    }
}