@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import '_colors.scss';

body {
  background-color: var(--background-primary);
  color: var(--text-primary);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
textarea {
  background-color: transparent;
  border-radius: 12px;
  height: 46px;
  padding: 0 16px;
}

button {
  border-radius: 12px;
  font-weight: 500;
  height: 46px;
  padding: 0 14px;
  width: 100%;
}

h2 {
  font-size: 1.25rem;
  font-weight: 600;
}

ol {
  list-style-type: decimal;
  margin-left: 12px;
}

::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}
::-webkit-scrollbar-track {
  background: var(--delimiter);
}
::-webkit-scrollbar-thumb {
  background: var(--background-active);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--background-active);
}

svg {
  fill: var(--text-secondary);
}

.grecaptcha-badge { 
  visibility: hidden;
}