:root {
    --brand-primary: #b800ff; 
    --background-primary: #f5f6fa;
    --background-secondary: #ffffff;
    --background-active: #ffffff;
    --delimiter: #ebecf0;
    --text-primary: #010101;
    --text-secondary: #828285;
    --message-bubble-background-other: #ffffff;
    --message-bubble-background-self: #c303e5;
    --message-bubble-shadow-other: 0 1.5px 3px 0 rgba(0, 0, 0, 0.063);
    --message-bubble-shadow-self: none;
    --message-bubble-text-other: #010101;
    --message-bubble-text-self: #ffffff;
    --message-bubble-text2-other: #828285;
    --message-bubble-text2-self: rgba(255, 255, 255, 0.67);
    --send-button-fill: var(--brand-primary);
}

html[data-theme="dark"] {
    --brand-primary: #b800ff; 
    --background-primary: #18181b;
    --background-secondary: #1e1e21;
    --background-active: #29292d;
    --delimiter: #2e2e32;
    --text-primary: #ffffff;
    --text-secondary: #999999;
    --message-bubble-background-other: #3f3f45;
    --message-bubble-background-self: #c303e5;
    --message-bubble-shadow-other: none;
    --message-bubble-shadow-self: none;
    --message-bubble-text-other: #ffffff;
    --message-bubble-text-self: #ffffff;
    --message-bubble-text2-other: rgba(255, 255, 255, 0.67);
    --message-bubble-text2-self: rgba(255, 255, 255, 0.67);
    --send-button-fill: var(--text-primary);
}

