@import '../../styles/_colors';

.button {
    align-items: center;
    background: none;
    background-color: #000000;
    color: #ffffff;
    display: flex;
    justify-content: center;
    svg {
        fill: #ffffff;
        margin-right: 6px;
    }
}