@import '../../styles/_colors';

.button {
    border-radius: 21px;
    width: 42px;
    height: 42px;
    svg {
        fill: var(--send-button-fill);
        width: 26px;
        height: 26px;
    }
}