@import '../../styles/_colors';

.bar {
    align-items: center;
    background-color: var(--background-secondary);
    display: flex;
    flex-direction: row;
    height: 68px;
    justify-content: space-between;
    min-height: 68px;
    padding: 0 14px;
    user-select: none;
    .user {
        align-items: center;
        display: flex;
        flex-direction: row;
    }
}