@import '../../styles/_colors';

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.messages {
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 7px 14px;
    transition: padding-bottom 0.3s;
}

.section {
    display: flex;
    flex-direction: column-reverse;
}

.inputContainer {
    background-color: var(--background-secondary);
    input[type="text"] {
        background-color: var(--background-secondary);
        border: 0;
        border-radius: 0;
        height: 57px;
        padding-bottom: 1px;
        width: 100%;
    }
}