@import '../../styles/_colors';

.messageBubble {
    .bubble {
        background-color: var(--message-bubble-background-other);
        box-shadow: var(--message-bubble-shadow-other);
        color: var(--message-bubble-text-other);
        display: inline-block;
        margin: 1px 0;
        max-width: min(560px, 70%); 
        text-align: left;
        .deleted {
            color: rgba(255, 255, 255, 0.67);
            font-style: italic;
            padding: 7px 12px 8px 12px;
        }
        .sender {
            font-weight: 600;
            padding: 6px 12px 0 12px;
        }
        .top {
            border-bottom: 1px solid rgba(255,255,255,0.07);
            color: var(--message-bubble-text2-other);
            font-size: 0.9rem;
            margin: 8px 12px 0 12px;
            padding: 0 0 6px 0;
            font-style: italic;
        }
        .bottom {
            padding: 7px 12px 8px 12px;
        }
    }
    &.self {
        text-align: right;
        .bubble {
            background: var(--message-bubble-background-self);
            background: linear-gradient(180deg, #c303e5 0%, #a400ea 100%);
            box-shadow: var(--message-bubble-shadow-self);
            background-color: #b800ff;
            color: var(--message-bubble-text-self);
            .top {
                border-color: rgba(255,255,255,0.2);
                color: var(--message-bubble-text2-self);
            }
            .audio-message {
                svg {
                    fill: var(--message-bubble-text-self);
                }
            }
        }
    }
}

.media {
    overflow: hidden;
    padding: 5px;
    width: 360px;
    &.with-message {
        padding-bottom: 0;
    }
    .image-container {
        display: flex;
        flex-wrap: wrap;
        height: 360px;
    }
    .image {
        background-position: center;
        background-size: cover;
        border-radius: 17px;
        min-width: 50%;
        flex: 1;
    }
    .video {
        flex: 1;
    }
}

.audio-message {
    padding: 8px 6px;
    .transcript {
        padding: 0 6px 10px 6px;
    }
    .transcript-word {
        color: rgba(255,255,255,0.67);
        transition: color 0.1s;
        &.active {
            color: #ffffff;
        }
    }
    svg {
        fill: var(--message-bubble-text-other);
    }
}