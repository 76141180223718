.auth-container {
    align-items: center;
    background-color: var(--background-primary);
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;
}

.auth {
    background-color: var(--background-secondary);
    border-radius: 1.5rem;
    max-width: 100%;
    padding: 3rem;
    width: 420px;
}