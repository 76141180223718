@import '../../styles/_colors';

.container {
    position: relative;
    user-select: none;
}

.menu {
    background-color: var(--background-secondary);
    border: 1px solid var(--delimiter);
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
    min-width: 260px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    & > div {
        align-items: center;
        border-bottom: 1px solid var(--delimiter);
        display: flex;
        height: 52px;
        padding: 0 14px;
        &:hover {
            background-color: var(--background-active);
        }
    }
    & > div:nth-last-child(1) {
        border-bottom: 0;
    }
}